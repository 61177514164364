$color-loader-bg: #202020;

$color-1: #2980b9;
$color-2: #7f8c8d;
$color-3: #f1c40f;

$color-scrollbar: #84a1d2;

$color-logo-bg-1: #222;
$color-logo-bg-2: #333;
$color-bulb-blue-light: #358eac;
$color-bulb-blue-dark: #0b3854;
$color-bulb-yellow-light: #f1c40f;
$color-bulb-yellow-dark: #e09d16;