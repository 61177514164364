@import '../../colors';

section {
  &.references {
    background-color: #eee;
    min-height: auto;
    position: relative;
    display: flex;
    flex-direction: column;

    .gallery {
      margin: 0 -30px;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
      position: relative;

      &.collapsed {
        max-height: 500px !important;

        &:after {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          content: '';
          height: 40px;
          background: linear-gradient(0deg, $color-loader-bg 0%, transparent 100%);
        }

        button {
          opacity: 1;
          pointer-events: all;
        }
      }

      button {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        pointer-events: all;

        &::before {
          content: '';
          top: 50%;
          left: 100%;
          transform: translateY(-50%);
          position: absolute;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          background-color: $color-3;
          transition: all .3s ease-in-out;
        }

        &:hover::before {
          left: calc(100% - 20px);
        }
      }

      div.img-container {
        overflow: hidden;
        cursor: pointer;
        position: relative;

        &::after {
          font-family: LineIcons !important;
          font-style: normal;
          font-weight: 400;
          font-feature-settings: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          content: '\e97c';
          color: transparent;
          position: absolute;
          font-size: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;
          $padding: 0;
          top: $padding;
          left: $padding;
          right: $padding;
          bottom: $padding;
          transition: all 0.3s ease-in-out;
          pointer-events: none;
        }

        img {
          width: 100%;
          height: 100%;
          transform-origin: center center;
          transform: scale(1.05);
          transition: transform 0.3s ease-in-out;
        }

        &:hover {
          img {
            transform: scale(1);
          }

          &:after {
            $padding: 10px;
            top: $padding;
            left: $padding;
            right: $padding;
            bottom: $padding;
            background-color: rgba($color-1, 0.3);
            color: $color-1;
          }
        }
      }
    }
  }
}