@import '../../colors';
@import '../../variables';

section {
  &.header {
    min-height: 40vh;
    position: relative;
    overflow: hidden;
    background: url(../../../public/img/bg3.jpg) no-repeat center center;
    background-size: cover;

    &::before {
      content: '';
      position: absolute;
      opacity: 0.9;
      width: 100%;
      height: 100%;
      background-color: $color-1;
      background-size: 5px 5px;
      background-image: repeating-linear-gradient(45deg, $color-1 0, $color-1 0.5px, lighten($color-1, 5%) 0, lighten($color-1, 5%) 50%);
      left: 0;
    }

    .animation {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .container {
      width: 100%;

      .logo {
          $padding: 20px;

          width: $logo-size;
          height: $logo-size;
          padding: $padding;
          border-radius: 50%;
          border: 4px solid white;
          position: relative;

          display: flex;
          align-items: center;
          justify-content: center;

          background-color: $color-logo-bg-1;
          background-size: 5px 5px;
          background-image: repeating-linear-gradient(45deg, $color-logo-bg-2 0, $color-logo-bg-2 0.5px, $color-logo-bg-1 0, $color-logo-bg-1 50%);
          
          h1 {
            font-family: 'Akshar', sans-serif;
            position: absolute;
            font-size: 48px;
            line-height: 48px;
            height: 48px;
            color: white;
            font-weight: 600;
            margin: 0;
            text-shadow: 
              0 0 10px $color-bulb-blue-dark,
              0 0 6px $color-bulb-blue-dark,
              0 0 4px $color-bulb-blue-dark;
          }

          svg {
            position: absolute;
            z-index: 0;
            width: calc(100% - $padding);
            height: calc(100% - $padding);
            left: calc($padding / 2);
            right: calc($padding / 2);
            top: calc($padding / 2);
            bottom: calc($padding / 2);
            transform: rotate(45deg);

            path {
              transition: all 0.3s ease-in-out;
            }
          }

          &:hover {
            svg {
              #bg, #inner path, #round path, #border {
                transform: translate(0px, -10px);
              }

              #bottom path {
                transform: translate(0px, 10px);
              }

              #border {
                filter: drop-shadow(0 0 20px $color-bulb-yellow-light);
              }

              #round path {
                filter: drop-shadow(0 0 30px $color-bulb-yellow-light);
              }
            }
          }
      }

      #budapest-map {
        position: absolute;
        right: 1vw;
        width: 28vw;
        max-height: 80%;
        aspect-ratio: 600/566;

        #bp16, #bp17, #bp14, #bp15, #bp13, #bp18, #bp10, #bp4 {
          fill: lighten($color-1, 5%);
          // fill: $color-3;
        }
      }

      .content {
        max-width: 40vw;
        margin-left: 50px;
        color: white;
        position: relative;
        display: flex;
        align-items: center;

        h2 {
          font-size: 50px;        
          line-height: 50px;
          position: relative;
          height: 50px;

          span {
            position: absolute;
            bottom: -4px;

            $animation-time: 8s;

            &:nth-child(1) {
              opacity: 0;
              animation: animteText $animation-time ease-in-out infinite;
            }

            &:nth-child(2) {
              opacity: 0;
              animation: animteText $animation-time ease-in-out infinite;
              animation-delay: calc($animation-time / 3);
            }

            &:nth-child(3) {
              opacity: 0;
              animation: animteText $animation-time ease-in-out infinite;
              animation-delay: calc($animation-time / 3 * 2);
            }

            @keyframes animteText {
              0% {
                opacity: 0;
                filter: blur(0);
              }

              5% {
                opacity: 1;
                filter: blur(0);
              }

              20% {
                opacity: 1;
                filter: blur(0);
              }

              30% {
                opacity: 0;
                filter: blur(10px);
              }
            }
          }
        }

        h3 {
          font-size: 16px;
          color: white;
        }
      }

      .yellow-square {
        position: absolute;
        left: calc(100% - 40vw);
        width: 100%;
        height: 200%;
        border-radius: 100px;
        background-color: rgba($color-3, 0.9);
        transform: rotate(36deg);
        bottom: 33%;
        transform-origin: bottom left;

        &::before {
          $size: -11.5px;
          content: '';
          position: absolute;
          top: $size;
          bottom: $size;
          left: $size;
          right: $size;
          border: 2px solid rgba($color-3, 0.7);
          border-radius: 110px;
        }

        &::after {
          $size: -6px;
          content: '';
          position: absolute;
          top: $size;
          bottom: $size;
          left: $size;
          right: $size;
          border: 2px solid $color-3;
          border-radius: 106px;
        }
      }      
    }

    // @media only screen and (max-width: 1200px) {
    //   #budapest-map {
    //     width: 25vw;
    //     max-height: 70%;
    //   }
    // }

    @media only screen and (max-width: 800px) {
      .container {
        flex-direction: column;
        min-height: auto;
        align-items: center;
        margin-bottom: 30px;

        .content {
          max-width: none;
          flex: 1;
          margin: 0;

          h2 {
            font-size: 45px;
            span {
              transform: translateX(-50%);
            }
          }
        }

        .yellow-square, #budapest-map {
          display: none;
        }
      }
    }
  }
}