// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Google+Sans:400,500,700|Google+Sans+Text:400&lang=hu');
// @import url('https://fonts.googleapis.com/css?family=Google+Sans+Text:400&text=%E2%86%90%E2%86%92%E2%86%91%E2%86%93&lang=hu');

@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900');

@import url('https://fonts.googleapis.com/css2?family=Akshar:wght@300&display=swap');

@import 'line-icons/line-icons.min.css';
@import 'font-awesome/font-awesome.min.css';

@import 'normalize.css';
@import 'colors';
@import 'variables';


@font-face {
    font-family: 'icomoon';
    src:  url('icomoon/icomoon.eot?dhfl30');
    src:  url('icomoon/icomoon.eot?dhfl30#iefix') format('embedded-opentype'),
        url('icomoon/icomoon.ttf?dhfl30') format('truetype'),
        url('icomoon/icomoon.woff?dhfl30') format('woff'),
        url('icomoon/icomoon.svg?dhfl30#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}  

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;
}

html {
    line-height: 1.15;
    line-height: 26px;
    letter-spacing: 0.5px;
    overflow-x: hidden;
    // color: white;
    // background-color: #202020;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

section {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 51px;
    line-height: initial;
    padding: 0 30px;
    position: relative;

    .container {
        max-width: 1140px;
        margin: 50px 0;
        display: flex;
    }

    .navigation-top {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
}

h2, h3, h4, h5 {
    font-weight: 400;
}

button.button {
    font-size: 14px;
    padding: 5px 34px;
    line-height: 1.8em;
    border-radius: 50px;
    overflow: hidden;
    z-index: 2;
    display: inline-block;
    font-size: 17px;
    border: 2px solid transparent;
    letter-spacing: 1px;
    text-transform: capitalize;
    background-color: $color-1;
    color: white;
    border: 2px solid white;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;

    &::before {
        content: '';
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
        position: absolute;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: $color-3;
        transition: all .3s ease-in-out;
    }

    &:hover::before {
        left: calc(100% - 20px);
    }
}

::-webkit-scrollbar {
    width: 7px;
    background-color: $color-scrollbar;
}

::-webkit-scrollbar-track {
    background-color: #e9edf0;
    border-left: 1px solid #ededed;
}

::-webkit-scrollbar-thumb {
    background: $color-scrollbar;
}