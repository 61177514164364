@import '../../colors';
@import '../../variables';

* {
  scroll-margin-top: 70px;
}

header {
  display: flex;
  // position: relative;

  &.fixed {
    nav {
      position: fixed;
      top: 0;
      background-color: white;
      box-shadow: 0 -10px 10px 15px rgba(black, 0.3);
      
      > .logo {
        opacity: 1;
      }

      ul li span {
        color: black;
      }
    }

    .phone {
      color: black;
    }

    .phone, .facebook {
      position: fixed;
      top: 0;
    }
  }

  nav {
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out, top 0s;
    position: absolute;
    z-index: 10;
    
    > .logo {
      display: block;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      cursor: pointer;

      position: absolute;
      left: 20px;

      $nav-logo-size: 50px;

      width: $nav-logo-size;
      height: $nav-logo-size;
      padding: 8px;
      border-radius: 50%;
      border: 4px solid white;

      display: flex;
      align-items: center;
      justify-content: center;

      background-color: $color-logo-bg-1;
      background-size: 5px 5px;
      background-image: repeating-linear-gradient(45deg, $color-logo-bg-2 0, $color-logo-bg-2 0.5px, $color-logo-bg-1 0, $color-logo-bg-1 50%);
      svg {
        transform: rotate(45deg);
      }
    }

    ul {
      display: flex;
      flex-direction: row;
      padding-left: 0;
      margin: 0;
      list-style: none;
      transition: all 0.3s ease-in-out;

      li {
        font-size: 15px;
        font-weight: 500;
        padding: 0;
        margin: 0;
        text-transform: uppercase;

        &.active, &:hover {
          span {
            color: $color-1;

            &::after {
              color: $color-1;
              text-shadow: 10px 0 $color-2, -10px 0 $color-3;
            }
          }
        }

        span {
          color: white;
          margin: 0 1rem;
          transition: color 0.3s;
          position: relative;
          display: inline;
          cursor: pointer;

          &::after {
            position: absolute;
            top: 80%;
            left: 50%;
            color: transparent;
            content: '•';
            text-shadow: 0 0 transparent;
            font-size: 1.2em;
            transition: text-shadow 0.3s, color 0.3s;
            transform: translateX(-50%);
            pointer-events: none;
          }
        }
      }
    }
  }

  .facebook, .phone {
    transition: all 0.3s ease-in-out;
    position: absolute;
    z-index: 10;
    font-size: 14px;
    line-height: 70px;
    color: white;
    text-decoration: none;

    &:hover {
      color: $color-1;
    }
  }

  .phone {
    right: 80px;
  }

  .facebook {
    right: 30px;
    font-size: 18px;
    color: #4267B2;

    i::before {
      content: "\ea91";
      font-family: 'icomoon';
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      // line-height: 1;

      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  @media only screen and (max-width: 1000px) {
    .phone {
      &::before {
        font-size: 18px;
        content: "\e942";
        font-family: 'icomoon';
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        // line-height: 1;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
  
      span {
        display: none;
      }
    }
  }

  @media only screen and (max-width: 800px) {
    nav {
      display: none;
    }

    .phone, .facebook {
      position: fixed;
      top: 0;
      line-height: 40px;
      color: $color-1 !important;
    }

    .phone {
      left: 10px;
      right: auto;

      &::after {
        position: absolute;
        bottom: -10px;
        right: -20px;
        width: 100px;
        height: 100px;
        background: white;
        content: '';
        z-index: -1;
        border-radius: 50%;
      }
    }

    .facebook {
      right: 10px;

      &::after {
        position: absolute;
        bottom: -10px;
        left: -20px;
        width: 100px;
        height: 100px;
        background: white;
        content: '';
        z-index: -1;
        border-radius: 50%;
      }
    }
  }
}