@import '../../colors';

section {
  &.services {
    background-color: $color-logo-bg-1;
    
    .container {  

      flex-wrap: wrap;
      justify-content: center;
      align-items: stretch;
      margin-top: 100px;

      .service {
        padding: 20px 30px;
        margin: 20px 20px;
        background-color: #EEE;
        display: flex;
        flex-direction: column;
        flex: 0 0 calc(33% - 40px);
        transition: all 0.15s ease-in-out;
        border: 1px solid $color-1;
        position: relative;
        border-radius: 3px;

        &:before, &:after {
          pointer-events: none;
        }
        
        &:before {
          $size: -14px;
          content: '';
          position: absolute;
          top: calc($size / 2);
          bottom: calc($size / 2);
          left: $size;
          right: $size;
          border: 1px solid $color-3;
          border-radius: 3px;
          transition: all 0.15s ease-in-out;
        }

        &:after {
          $size: -14px;
          content: '';
          position: absolute;
          top: $size;
          bottom: $size;
          left: calc($size / 2);
          right: calc($size / 2);
          border-radius: 3px;
          border: 1px solid $color-1;
          transition: all 0.15s ease-in-out;
        }

        &:hover {
          transform: scale(1.02);

          &::before {
            $size: -14px;
            content: '';
            position: absolute;
            top: $size;
            bottom: $size;
            left: calc($size / 2);
            right: calc($size / 2);
          }

          &::after {
            $size: -15px;
            content: '';
            position: absolute;
            top: calc($size / 2);
            bottom: calc($size / 2);
            left: $size;
            right: $size;
          }

          .icon::after {
            top: -2px;
            bottom: -2px;
            left: -2px;
            right: -2px;
          }
        }

        .icon {
          font-size: 28px;
          height: 45px;
          width: 45px;
          line-height: 45px;
          background-color: $color-1;
          display: inline-block;
          vertical-align: middle;
          text-align: center;
          border-radius: 50px;
          color: #fff;
          align-self: center;
          position: relative;

          &::before {
            font-family: 'icomoon';
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            // line-height: 1;

            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }

          &::after {
            position: absolute;
            left: -5px;
            right: -5px;
            top: -5px;
            bottom: -5px;
            border-radius: 50%;
            content: '';
            border: 1px solid $color-1;
            transition: all 0.15s ease-in-out;
          }
        }

        &:nth-child(1) .icon::before {
          content: "\e986";
          font-size: 26px;
        }

        &:nth-child(2) .icon::before {
          content: "\e907";
          padding-left: 1.5px;
        }

        &:nth-child(3) .icon::before {
          content: "\e908";
        }
        
        &:nth-child(4) .icon::before {
          content: "\e903";
        }

        &:nth-child(5) .icon::before {
          content: "\e996";
          padding-right: 1px;
        }

        ul, p {
          font-size: 16px;
          margin: 20px 0 0;
          padding: 0;
        }

        p {
          text-align: center;

          &.sub {
            font-size: 12px;
            color: lighten(black, 20%);
          }
        }

        ul {
          margin-top: 10px;
          list-style-type: none;
          align-items: center;

          li {
            position: relative;
            // padding-left: 20px;

            &::before {
              // font-family: LineIcons !important;
              // font-style: normal;
              // font-weight: 400;
              // font-feature-settings: normal;
              // font-variant: normal;
              // text-transform: none;
              // line-height: 1;
              // font-size: 12px;
              // -webkit-font-smoothing: antialiased;
              // content: '\e956';
              // color: $color-1;
              // position: absolute;
              // left: 0;
              // top: 2px;
              content: '- ';
            }
          }

          li + li {
            margin-top: 10px;
          }
        }
      }

      @media only screen and (max-width: 800px) {
        flex-wrap: nowrap;
        flex-direction: column;
        margin: 30px 0;
      }
    }
  }
}