@import '../../colors';

section {
  &.contact {

    .container {

      width: 100%;

      display: flex;
      align-items: center;  

      .contact-form {
        // padding-right: 50px;
        width: 100%;

        h4 {
          color: $color-1;
          font-size: 18px;
          margin: 0;
        }

        h3 {
          font-size: 48px;
          margin: 0 0 20px;
        }

        form {
          display: flex;
          flex-direction: column;

          .input {
            position: relative;
            margin-bottom: 40px;
            display: flex;

            label {
              font-size: 16px;
              position: absolute;
              left: 10px;
              top: 5px;
              color: lighten($color-2, 10%);
              transition: all 0.15s ease-in-out;
              pointer-events: none;
            }

            &:focus-within {
              label {
                top: -20px;
                font-size: 14px;
                color: black;
              }
            }

            input, textarea {
              box-sizing: border-box;
              min-width: auto;
              width: 100%;
              font-size: 16px;
              // border: none;
              border: 1px solid $color-2;
              border-radius: 5px 5px 0 0;
              border-bottom-color: $color-1;
              outline: none;
              resize: none;
              padding: 5px 10px;
              background: lighten($color-2, 45%);

              &:not(:placeholder-shown) + label {
                top: -20px;
                font-size: 14px;
                color: black;
              }
            }

            &.has-error {
              input, textarea {
                border-color: red;
              }
            }

            textarea {
              min-height: 10rem;
            }
          }

          button {
            width: 100%;
          }

          span {
            font-size: 18px;
            text-align: center;
            color: $color-1;
          }
        }
      }

      .info {
        background-color: $color-1;
        padding: 10px 30px;
        box-shadow: 10px 5px 0 $color-3,
        -5px -7px 0 $color-2;
        margin-left: 50px;

        color: white;

        > div {
          display: flex;

          h4, h5 {
            font-size: 16px;
            margin: 0;
          }

          h5 {
            margin-top: 5px;
            font-weight: 500;
          }

          a {
            color: white;
            text-decoration: none;
            white-space: nowrap;
          }

          & + div {
            margin-top: 30px;
          }
        }
      }

      @media only screen and (max-width: 800px) {
        flex-direction: column;
        align-items: stretch;

        .contact-form {
          padding: 0;
        }

        .info {
          margin: 40px 0 0;
        }
      }
    }
  }
}