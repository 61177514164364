@import '../../colors';

section {
  &.about {
    background-color: $color-loader-bg;
    color: white;

    .container {  

      align-items: center;

      .content-container {

        h3 {
          font-size: 48px;
          margin: 0 0 25px;
        }

        p {
          font-size: 16px;
          margin: 20px 0 0;

          span {
            color: $color-1;
          }
        }
      }

      .image-container {
        margin-left: 50px;

        .image {
          width: 500px;
          max-width: calc(100vw - 40px);
          background-color: #222;
          background-size: 5px 5px;
          background-image: repeating-linear-gradient(45deg, $color-1 0, $color-1 0.5px, $color-3 0, $color-3 50%);
          aspect-ratio: 960/718;
          position: relative;
          box-shadow: 
            -10px 5px 0 $color-2,
            10px -20px 0 $color-1;

            &::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background: url(../../../public/img/about.png);
              aspect-ratio: 960/718;
              background-size: contain;
            }
        }
      }

      @media only screen and (max-width: 1000px) {
        .image-container .image {
          width: 400px;
        }
      }

      @media only screen and (max-width: 800px) {
        flex-direction: column;

        .content-container {
          padding-left: 0;
        }

        .image-container {
          margin: 80px 0 0;

          .image {
            width: calc(100vw - 80px);
          }
        }
      }
    }

  }
}